import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link, useLocation } from "react-router-dom";
import {
  API_URL,
  TRUCKS_B,
  TRUCKS_L,
  formatDate,
  isExpiredJWT,
  revertName,
} from "../utils";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Checkbox,
  Modal,
  NumberInput,
  Select,
  Table,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { FaEdit } from "react-icons/fa";
import CustomError from "./CustomError";

function ListTransports() {
  const { auth, refresh } = useContext(AuthContext);
  const [transports, setTransports] = useState([]);
  const [users, setUsers] = useState([]);
  const [opened, { open, close }] = useDisclosure(false);
  const [err, setErr] = useState(null);
  let location = useLocation();

  const path = location.pathname.match(/\/l\/.*/i) ? "l" : "b";

  const { control, handleSubmit } = useForm({
    defaultValues: {
      added_by_user: "",
      start: "",
      end: "",
      pending: false,
      results: 50,
    },
  });

  useEffect(() => {
    async function getTransports() {
      try {
        const res = await Promise.all([
          axios.get(
            API_URL + `/api/${path}/transport/?pending=false&results=50`,
            {
              headers: { Authorization: `Bearer ${auth.aT}` },
            }
          ),
          axios.get(API_URL + `/api/user/?normal=${path}`, {
            headers: { Authorization: `Bearer ${auth.aT}` },
          }),
        ]);
        setTransports(res[0].data);
        setUsers(res[1].data);
      } catch (err) {
        console.error(err);
        setErr(err);
      }
    }
    if (auth.aT && !isExpiredJWT(auth.user.exp)) getTransports();
    else refresh();
  }, [auth, refresh, path]);

  async function onSubmit(data) {
    let q = API_URL + `/api/${path}/transport/?`;
    q += `pending=${data.pending}`;
    q += data.added_by_user
      ? `&added_by_user=${revertName(data.added_by_user)}`
      : "";
    q += data.truck ? `&truck=${data.truck}` : "";
    q += data.start ? `&start=${data.start.toISOString()}` : "";
    q += data.end ? `&end=${data.end.toISOString()}` : "";
    q += `&results=${data.results}`;
    const aT = isExpiredJWT(auth.user.exp) ? await refresh() : auth.aT;
    try {
      const res = await axios.get(q, {
        headers: { Authorization: `Bearer ${aT}` },
      });
      setTransports(res.data);
    } catch (err) {
      console.error(err);
      setErr(err);
    }
    close();
  }

  return err ? (
    <CustomError error={err} />
  ) : (
    <div>
      <Modal
        lockScroll={false}
        opened={opened}
        onClose={close}
        title="Filtreaza"
        classNames={{
          title: "!text-2xl !font-bold",
          body: "flex flex-col gap-4",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
          <Controller
            name="pending"
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                classNames={{ label: "!font-medium" }}
                checked={field.value}
                label="In asteptare"
                labelPosition="left"
              />
            )}
          />
          <Controller
            name="added_by_user"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Sofer"
                clearable
                placeholder="Selecteaza sofer"
                nothingFoundMessage="Sofer inexistent"
                searchable
                data={users.map((user) => user.formattedName)}
              />
            )}
          />
          <Controller
            name="truck"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Camion"
                clearable
                placeholder="Selecteaza camion"
                nothingFoundMessage="Sofer inexistent"
                searchable
                data={path === "l" ? TRUCKS_L : TRUCKS_B}
              />
            )}
          />
          <Controller
            name="start"
            control={control}
            render={({ field }) => (
              <DateTimePicker
                {...field}
                label="Inceput"
                clearable
                placeholder="Data si ora de inceput"
              />
            )}
          />
          <Controller
            name="end"
            control={control}
            render={({ field }) => (
              <DateTimePicker
                {...field}
                label="Sfarsit"
                clearable
                placeholder="Data si ora de sfarsit"
              />
            )}
          />
          <Controller
            name="results"
            control={control}
            render={({ field }) => (
              <NumberInput
                {...field}
                label="Rezultate"
                placeholder="Limita rezultate"
                min={1}
              />
            )}
          />
        </form>
        <Button type="submit" onClick={handleSubmit(onSubmit)}>
          Trimite
        </Button>
      </Modal>

      <h1 className="text-2xl font-bold mb-4">Lista transporturi</h1>
      <Button onClick={open} type="button" className="mb-4">
        Filtreaza
      </Button>

      {path === "l" ? (
        <MantineTableL transports={transports} />
      ) : (
        <MantineTableB transports={transports} />
      )}
    </div>
  );
}

export default ListTransports;

function MantineTableL({ transports }) {
  const rows = transports.map((transport) => (
    <Table.Tr key={transport._id}>
      <Table.Td>
        <Link to={transport._id}>
          <FaEdit className="text-xl " />
        </Link>
      </Table.Td>
      <Table.Td>{transport.added_by_user.formattedName}</Table.Td>
      <Table.Td>{formatDate(transport.date)}</Table.Td>
      <Table.Td>{transport.truck}</Table.Td>
      <Table.Td>{transport.trailer}</Table.Td>
      <Table.Td>{transport.tur}</Table.Td>
      <Table.Td>{transport.ruta.join("-")}</Table.Td>
      <Table.Td>{transport.kms}</Table.Td>
      <Table.Td>{transport.time}</Table.Td>
    </Table.Tr>
  ));

  return (
    <Table.ScrollContainer minWidth={300}>
      <Table
        withTableBorder
        withColumnBorders
        highlightOnHover
        verticalSpacing={"sm"}
        classNames={{ thead: "!sticky" }}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Edit</Table.Th>
            <Table.Th>Sofer</Table.Th>
            <Table.Th>Data</Table.Th>
            <Table.Th>Camion</Table.Th>
            <Table.Th>Remorca</Table.Th>
            <Table.Th>Tur</Table.Th>
            <Table.Th>Ruta</Table.Th>
            <Table.Th>Km</Table.Th>
            <Table.Th>Durata</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
}

function MantineTableB({ transports }) {
  const rows = transports.map((transport) => (
    <Table.Tr key={transport._id}>
      <Table.Td>
        <Link to={transport._id}>
          <FaEdit className="text-xl " />
        </Link>
      </Table.Td>
      <Table.Td>{transport.added_by_user.formattedName}</Table.Td>
      <Table.Td>{formatDate(transport.date)}</Table.Td>
      <Table.Td>{transport.truck}</Table.Td>
      <Table.Td>{transport.trailer}</Table.Td>
      <Table.Td>{transport.loc_incarcare}</Table.Td>
      <Table.Td>{transport.loc_livrare}</Table.Td>
      <Table.Td>{transport.aviz_interbulk}</Table.Td>
      <Table.Td>{transport.aviz_safirtrans}</Table.Td>
      <Table.Td>{transport.cantitate}</Table.Td>
    </Table.Tr>
  ));

  return (
    <Table.ScrollContainer minWidth={300}>
      <Table
        withTableBorder
        withColumnBorders
        highlightOnHover
        verticalSpacing={"sm"}
        classNames={{ thead: "!sticky" }}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Edit</Table.Th>
            <Table.Th>Sofer</Table.Th>
            <Table.Th>Data</Table.Th>
            <Table.Th>Camion</Table.Th>
            <Table.Th>Remorca</Table.Th>
            <Table.Th>Incarcare</Table.Th>
            <Table.Th>Livrare</Table.Th>
            <Table.Th>Aviz Interbulk</Table.Th>
            <Table.Th>Aviz Safirtrans</Table.Th>
            <Table.Th>Cantitate</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
}
