import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import {
  API_URL,
  LIVRARE_B,
  INCARCARE_B,
  TRAILERS_B,
  TRUCKS_B,
  formatName,
  isExpiredJWT,
  revertName,
} from "../utils";
import { Button, NumberInput, Select } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import useFeedback from "../hooks/useFeedback";
import CustomError from "./CustomError";

let cfgUsers = [];

function CreateTransportB() {
  const { auth, refresh } = useContext(AuthContext);
  const isAdmin = auth.user.permissions.includes("Admin");
  const [users, setUsers] = useState(null);
  const [err, setErr] = useState(null);
  const setFeedback = useFeedback();
  const navigate = useNavigate();

  const errorCfg = {
    added_by_user: {
      func: (v) => cfgUsers.find((user) => user.name === revertName(v)),
      msg: "Nume utilizator invalid",
    },
    truck: { func: (v) => TRUCKS_B.includes(v), msg: "Numar camion invalid" },
    trailer: {
      func: (v) => TRAILERS_B.includes(v),
      msg: "Numar remorca invalid",
    },
    loc_incarcare: {
      func: (v) => INCARCARE_B.includes(v),
      msg: "Adresa de incarcare invalida",
    },
    loc_livrare: {
      func: (v) => LIVRARE_B.includes(v),
      msg: "Adresa de livrare invalida",
    },
    aviz_interbulk: { func: (v) => v > 0, msg: "Aviz Interbulk invalid" },
    aviz_safirtrans: { func: (v) => v > 0, msg: "Aviz Safirtrans invalid" },
    cantitate: { func: (v) => v > 0, msg: "Cantitate invalida" },
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: "",
      added_by_user: "",
      truck: "",
      trailer: "",
      loc_incarcare: "",
      loc_livrare: "",
      aviz_interbulk: null,
      aviz_safirtrans: null,
      cantitate: null,
      pending: !isAdmin,
    },
  });

  // GET USERS
  useEffect(() => {
    async function getUsers() {
      try {
        const res = await axios.get(
          API_URL + "/api/user/?active=true&normal=b",
          {
            headers: { Authorization: `Bearer ${auth.aT}` },
          }
        );
        cfgUsers = res.data;
        setUsers(res.data);
      } catch (err) {
        console.error(err.response);
        setErr(err);
      }
    }

    if (isAdmin) {
      if (isExpiredJWT(auth.user.exp)) refresh();
      else getUsers();
    } else setUsers(auth.user);
  }, [auth, refresh, isAdmin]);

  async function onSubmit(data) {
    data.date = new Date().toISOString();
    const user = isAdmin
      ? users.find((user) => user.name === revertName(data.added_by_user))
      : auth.user;
    data.added_by_user = user._id;

    const aT = isExpiredJWT(auth.user.exp) ? await refresh() : auth.aT;
    try {
      await axios.post(API_URL + `/api/b/transport/create`, data, {
        headers: { Authorization: `Bearer ${aT}` },
      });
      setFeedback({
        type: "Success",
        active: true,
        msg: "Transportul a fost creat cu succes.",
      });
      navigate("/");
    } catch (err) {
      console.error(err);
      setFeedback({
        type: "Error",
        active: true,
        msg: "Transportul nu a putut fi creat.",
      });
    }
  }

  function formatNames() {
    return users.map((user) => formatName(user.name));
  }

  return err ? (
    <CustomError error={err} />
  ) : (
    <div>
      <h1 className="text-2xl font-bold mb-4">Creeaza transport</h1>
      {users && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="max-w-[400px] flex flex-col gap-2"
        >
          {isAdmin && (
            <>
              <Controller
                name="added_by_user"
                control={control}
                rules={{
                  validate: (v) =>
                    errorCfg.added_by_user.func(v) ||
                    errorCfg.added_by_user.msg,
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Sofer"
                    withAsterisk
                    placeholder="Selecteaza un sofer..."
                    nothingFoundMessage="Sofer inexistent"
                    searchable
                    data={formatNames()}
                  />
                )}
              />
            </>
          )}

          <Controller
            name="truck"
            control={control}
            rules={{
              validate: (val) => errorCfg.truck.func(val) || errorCfg.truck.msg,
            }}
            render={({ field }) => (
              <Select
                {...field}
                label="Camion"
                placeholder="Selecteaza un camion..."
                nothingFoundMessage="Camion inexistent"
                withAsterisk
                searchable
                data={TRUCKS_B}
              />
            )}
          />
          <Controller
            name="trailer"
            control={control}
            rules={{
              validate: (val) =>
                errorCfg.trailer.func(val) || errorCfg.trailer.msg,
            }}
            render={({ field }) => (
              <Select
                {...field}
                label="Remorca"
                placeholder="Selecteaza o remorca..."
                nothingFoundMessage="Remorca inexistenta"
                withAsterisk
                searchable
                data={TRAILERS_B}
              />
            )}
          />
          <Controller
            name="loc_incarcare"
            control={control}
            rules={{
              validate: (val) =>
                errorCfg.loc_incarcare.func(val) || errorCfg.loc_incarcare.msg,
            }}
            render={({ field }) => (
              <Select
                {...field}
                label="Adresa de incarcare"
                placeholder="Adresa de incarcare..."
                nothingFoundMessage="Adresa invalida"
                withAsterisk
                searchable
                data={INCARCARE_B}
              />
            )}
          />
          <Controller
            name="loc_livrare"
            control={control}
            rules={{
              validate: (val) =>
                errorCfg.loc_livrare.func(val) || errorCfg.loc_livrare.msg,
            }}
            render={({ field }) => (
              <Select
                {...field}
                label="Adresa de livrare"
                placeholder="Adresa de livrare..."
                nothingFoundMessage="Adresa invalida"
                withAsterisk
                searchable
                data={LIVRARE_B}
              />
            )}
          />
          <Controller
            name="aviz_interbulk"
            control={control}
            rules={{
              validate: (val) =>
                errorCfg.aviz_interbulk.func(val) ||
                errorCfg.aviz_interbulk.msg,
            }}
            render={({ field }) => (
              <NumberInput
                {...field}
                label="Aviz Interbulk"
                error={
                  errors.aviz_interbulk ? errors.aviz_interbulk.message : ""
                }
                placeholder="Aviz Interbulk..."
                min={1}
                withAsterisk
                decimalScale={0}
              />
            )}
          />
          <Controller
            name="aviz_safirtrans"
            control={control}
            rules={{
              validate: (val) =>
                errorCfg.aviz_safirtrans.func(val) ||
                errorCfg.aviz_safirtrans.msg,
            }}
            render={({ field }) => (
              <NumberInput
                {...field}
                label="Aviz Safirtrans"
                error={
                  errors.aviz_safirtrans ? errors.aviz_safirtrans.message : ""
                }
                placeholder="Aviz Safirtrans..."
                min={1}
                withAsterisk
                decimalScale={0}
              />
            )}
          />
          <Controller
            name="cantitate"
            control={control}
            rules={{
              validate: (val) =>
                errorCfg.cantitate.func(val) || errorCfg.cantitate.msg,
            }}
            render={({ field }) => (
              <NumberInput
                {...field}
                label="Cantitate"
                error={errors.cantitate ? errors.cantitate.message : ""}
                placeholder="Cantitate..."
                min={1}
                withAsterisk
              />
            )}
          />
          <Button
            type="submit"
            className="!w-[100px] mt-2"
            onClick={handleSubmit(onSubmit)}
          >
            Trimite
          </Button>
        </form>
      )}
    </div>
  );
}

export default CreateTransportB;
